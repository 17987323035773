import React, { memo } from "react";
import EditIcon from "@mui/icons-material/Edit";
import { getFileAddress, getFormattedDate, mapReturnData } from "utils";
import {
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Drawer,
  Button,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  Tooltip,
} from "@mui/material";
import { Attachment, Download, Warning } from "@mui/icons-material";
import FilterListIcon from "@mui/icons-material/FilterList";
import useReturnsManagement from "./index.hooks";
import style from "./index.style";
import ReturnTag from "../../components/ReturnTag";
import ReturnModalContent from "./ReturnModalContent";
import { REFUND_STATUS_LABELS } from "../../constants/contants";

type ReturnsManagementProps = {};

const ReturnsManagement = memo(({}: ReturnsManagementProps) => {
  const {
    returns,
    totalNumberOfElements,
    pageSize,
    handlePageSizeChange,
    page,
    handleChangePage,
    modalOpen,
    handleOpenModal,
    selectedItem,
    onConfirmReturn,
    setSelectedRow,
    selectedRow,
    getReasonTitle,
    imageModalOpen,
    handleImageModalOpen,
    openImage,
    handleChangeSearchValue,
    searchTextValue,
    showAll,
    handleShowAll,
    handleFiltersModal,
    resetFilters,
    setShouldFilter,
    filters,
    filtersModalOpen,
    dispatchFilters,
    getSelectCountries,
    downloadInvoice,
  } = useReturnsManagement();

  return (
    <Box>
      <Drawer
        anchor="right"
        open={filtersModalOpen}
        onClose={() => {
          handleFiltersModal();
          setShouldFilter(true);
        }}
      >
        <Box sx={style.filters}>
          <FormControl fullWidth>
            <InputLabel id="status-label">Status</InputLabel>
            <Select
              labelId="select-status"
              id="select-status"
              label="Country"
              value={filters?.status}
              onChange={(ev) =>
                dispatchFilters({ type: "status", payload: ev.target.value })
              }
            >
              <MenuItem key="all" value="">
                All
              </MenuItem>
              {REFUND_STATUS_LABELS.map((label: any) => (
                <MenuItem key={label} value={label}>
                  {label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="country-label">Country</InputLabel>
            <Select
              labelId="select-country"
              id="select-country"
              label="Country"
              value={filters?.country}
              onChange={(ev) =>
                dispatchFilters({ type: "country", payload: ev.target.value })
              }
            >
              <MenuItem key="all" value="">
                All
              </MenuItem>
              {getSelectCountries.map((country: any) => (
                <MenuItem key={country.countryCode} value={country.countryCode}>
                  {country.countryName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            label="Order id"
            value={filters?.orderId}
            onChange={(ev) =>
              dispatchFilters({
                type: "orderId",
                payload: Number(ev.target.value),
              })
            }
            fullWidth
          />
          <TextField
            label="Tracking code"
            value={filters?.trackingCode}
            onChange={(ev) =>
              dispatchFilters({
                type: "trackingCode",
                payload: ev.target.value,
              })
            }
            fullWidth
          />
          <TextField
            label="Trans ref"
            value={filters?.transRef}
            onChange={(ev) =>
              dispatchFilters({
                type: "transRef",
                payload: ev.target.value,
              })
            }
            fullWidth
          />
          <Button
            variant="contained"
            onClick={() => {
              setShouldFilter(true);
              handleFiltersModal();
            }}
          >
            Filter
          </Button>
          <Button variant="outlined" onClick={resetFilters}>
            Reset
          </Button>
        </Box>
      </Drawer>
      <Modal
        open={modalOpen}
        onClose={handleOpenModal}
        aria-labelledby="return-modal-title"
        aria-describedby="return-modal-description"
      >
        <ReturnModalContent
          product={selectedItem}
          closeModal={handleOpenModal}
          onConfirmReturn={onConfirmReturn}
        />
      </Modal>
      <Modal
        open={imageModalOpen !== -1}
        onClose={() => handleImageModalOpen(-1)}
        aria-labelledby="return-modal-title"
        aria-describedby="return-modal-description"
      >
        <Box
          onClick={() =>
            openImage(returns?.[selectedRow]?.items?.[imageModalOpen]?.pic?.url)
          }
          sx={style.modal}
        >
          <img
            src={returns?.[selectedRow]?.items?.[imageModalOpen]?.pic?.url}
            alt="open-attachment"
          />
        </Box>
      </Modal>
      <Box sx={style.wrapper}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <TextField
            label="Search"
            value={searchTextValue}
            onChange={handleChangeSearchValue}
            size="medium"
          />
          <FormControlLabel
            control={<Checkbox checked={!showAll} onChange={handleShowAll} />}
            label="Show to refund"
          />
          <Box>
            <IconButton
              aria-label="delete"
              size="large"
              onClick={() => {
                handleFiltersModal();
                setShouldFilter(false);
              }}
            >
              <FilterListIcon />
            </IconButton>
          </Box>
        </Box>
        <TableContainer sx={style.table} component={Paper}>
          <Table aria-label="returns-table">
            <TableHead>
              <TableRow>
                <TableCell>Request Date</TableCell>
                <TableCell>Order ID</TableCell>
                <TableCell>Return Code</TableCell>
                <TableCell>Customer Email</TableCell>
                <TableCell>Request Status</TableCell>
                <TableCell>Actions</TableCell>
                <TableCell>Credit notes</TableCell>
                <TableCell>Errors</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {returns?.map((item: any, index: number) => (
                <TableRow
                  key={item?._id}
                  onClick={() => setSelectedRow(index)}
                  sx={[
                    style.selectableRow,
                    selectedRow === index && style.selectedRow,
                  ]}
                >
                  <TableCell>{getFormattedDate(item?.requestDate)}</TableCell>
                  <TableCell>{item?.order?.ids?.source}</TableCell>
                  <TableCell>{item?.items?.[0]?.returnCode}</TableCell>
                  <TableCell>{item?.user?.email}</TableCell>
                  <TableCell>
                    <ReturnTag className={mapReturnData(item.items).status}>
                      {mapReturnData(item.items).status}
                    </ReturnTag>
                  </TableCell>
                  <TableCell>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      {!mapReturnData(item.items).creditNoteId && (
                        <IconButton
                          aria-label="edit"
                          size="small"
                          onClick={() => handleOpenModal(item)}
                        >
                          <EditIcon sx={{ fontSize: 20 }} />
                        </IconButton>
                      )}
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      {item.invoices.map((invoice: any) => (
                        <Box onClick={() => downloadInvoice(invoice)}>
                          <Download sx={style?.selectableRow} />
                        </Box>
                      ))}
                    </Box>
                  </TableCell>
                  <TableCell>
                    {item.errs.length ? (
                      <Tooltip
                        title={item?.errs?.map(
                          (err: any) => `${err?.message} \n`
                        )}
                        placement="left-start"
                        arrow
                      >
                        <Warning
                          sx={{
                            fontSize: 20,
                            cursor: "default",
                            color: "rgba(0,0,0,0.54)",
                          }}
                        />
                      </Tooltip>
                    ) : (
                      <Box />
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={totalNumberOfElements}
            rowsPerPage={pageSize}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handlePageSizeChange}
          />
        </TableContainer>
      </Box>
      {selectedRow !== -1 && (
        <Paper sx={{ mt: 2 }}>
          <TableContainer sx={style.table} component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Image</TableCell>
                  <TableCell>Product</TableCell>
                  <TableCell>Reason</TableCell>
                  <TableCell>Price</TableCell>
                  <TableCell>Notes</TableCell>
                  <TableCell>Attachments</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {returns?.[selectedRow]?.items?.map(
                  (item: any, index: number) => (
                    <TableRow>
                      <TableCell>
                        <img
                          style={{ height: 80, width: 80 }}
                          src={getFileAddress(
                            item?.product?.pictures?.[0]?.url
                          )}
                          alt=""
                        />
                      </TableCell>
                      <TableCell>{item?.product?.code}</TableCell>
                      <TableCell>
                        {getReasonTitle(item?.reason?.code)}
                      </TableCell>
                      <TableCell>
                        {
                          returns?.[selectedRow]?.order?.items?.[index]
                            ?.priceCost?.total
                        }{" "}
                        €
                      </TableCell>
                      <TableCell>{item?.note}</TableCell>
                      <TableCell>
                        {item?.pic?.url && (
                          <Box
                            onClick={() => handleImageModalOpen(index)}
                            sx={{ cursor: "pointer" }}
                          >
                            <Attachment />
                          </Box>
                        )}
                      </TableCell>
                    </TableRow>
                  )
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      )}
    </Box>
  );
});

export default ReturnsManagement;
